import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NbAuthService, NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { ValuesService } from './services/values.service';
import { MyAccountsService } from './services/my-accounts.service';
import { NbPasswordIdentityServer4AuthStrategy } from './agt/identity/services/identityserver4-token-strategy';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: 'home',
      icon: 'home'
    },
    {
      title: 'Solicitud de crédito',
      url: 'invoices-create-new',
      icon: 'wallet'
    },
    {
      title: 'Lista de solicitudes',
      url: 'invoices-list',
      icon: 'list'
    },
    {
      title: 'Lista usuarios',
      url: 'user-list',
      icon: 'list'
    },
    {
      title: 'Fechar Sessão',
      url: '/agt/identity/token-login',
      icon: 'exit'
    }

  ];


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: NbAuthService,
    public valuesService: ValuesService,
    private nbTokenService: NbTokenService,
    private myAccountsService: MyAccountsService
  ) {
    this.initializeApp();

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.valuesService.TOKEN = token.toString();
        this.valuesService.USER_ID = token.getPayload()['sub'];
        this.valuesService.ROLE = token.getPayload()['role'];
        this.valuesService.username = token.getPayload()['username'];
        this.valuesService.ISLOGGEDIN = true;
        this.myAccountsService.getSubAccountsRelated();
        this.myAccountsService.myAccounts();
      } /*else {
        this.valuesService.ISLOGGEDIN = false;
        this.authService.logout(NbPasswordIdentityServer4AuthStrategy.toString());
      }*/
    });

    this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.valuesService.TOKEN = token.toString();
        this.valuesService.USER_ID = token.getPayload()['sub'];
        this.valuesService.ROLE = token.getPayload()['role'];
        this.valuesService.username = token.getPayload()['username'];
        this.valuesService.ISLOGGEDIN = true;
        this.myAccountsService.getSubAccountsRelated();
        this.myAccountsService.myAccounts();
      } /*else {
        this.valuesService.ISLOGGEDIN = false;
        this.authService.logout(NbPasswordIdentityServer4AuthStrategy.toString());
      }*/
    });
  }

  logout() {
    this.valuesService.ISLOGGEDIN = false;
    this.nbTokenService.clear();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
