/**
 * E-Wallet
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Account { 
    id?: string | null;
    masterAccount?: string | null;
    description?: string | null;
    accountType?: string | null;
    currency?: string | null;
    subAccount?: Array<Account> | null;
    currentBalance?: number | null;
}

