export * from './passwords.service';
import { PasswordsService } from './passwords.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './status.service';
import { StatusService } from './status.service';
export * from './token.service';
import { TokenService } from './token.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [PasswordsService, ProfileService, StatusService, TokenService, UsersService];
