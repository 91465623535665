import { Injectable } from '@angular/core';
import { AccountsService, RelatedAccounts } from 'apis/ewallet';
import { MyInvoicesService } from './my-invoices.service';
import { UtilService } from './util.service';
import { ValuesService } from './values.service';

@Injectable({
  providedIn: 'root'
})
export class MyAccountsService {


  constructor(private accountsService: AccountsService,
              private myInvoicesService: MyInvoicesService,
              private valuesService: ValuesService,
              private utilService: UtilService) {

  }


  myAccounts() {
    this.accountsService.accountGet().pipe().subscribe(data => {
      this.valuesService.myAccount =  data as Account;
    }, error => {
      console.log(error);
    });
  }

  getSubAccountsRelated() {
    // account/{accountId}/sub-accounts-related
    this.accountsService.accountAccountIdSubAccountsRelatedGet(this.valuesService.USER_ID).pipe().subscribe(data => {
      console.log(data);
      this.valuesService.relatedAccountsArray = data as Array<RelatedAccounts>;
      // ONLIY TEST!!!
      // this.myInvoicesService.createInvoice(); // no anda y viene un par de veces
    }, error => {
      console.log(error);
    });
  }
}
