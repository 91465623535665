import { Injectable } from '@angular/core';
import { RelatedAccounts } from 'apis/ewallet';
import { Account } from '../../../apis/ewallet/model/account';

@Injectable({
  providedIn: 'root'
})
export class ValuesService {

  // USER VALUES
  TOKEN: string;
  USER_ID: string;
  ROLE: string;
  ISLOGGEDIN = false;
  username: string;
  myAccount: Account;
  relatedAccountsArray: Array<RelatedAccounts>;
  BALANCE = '0,00';

  constructor() { }
}
