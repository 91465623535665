import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbRegisterComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuard } from './guard/auth.guard';
const routes: Routes = [
  /*{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },*/
  // Add AGT Modules and pages
  /*{ path: '', redirectTo: 'user-list', pathMatch: 'full' },
  { path: '**', redirectTo: 'user-list' },*/
  {
    path: 'agt',
    loadChildren: () => import('./agt/agt.module')
      .then(m => m.AgtModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
       // redirectTo: '/identity/token-login',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        redirectTo: '/agt/identity/token-login',
        // component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  {
    /*
    :title: titulo de la pagina de feddback
    :text: subtitulo de pagina de feedback
    :btnText texto que va en el segundo boton (el primero va siempre a home)
    :path a donde navega el segundo boton
    */
    path: 'feedback/:title/:text/:btnText/:path',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule),
  },
  {
    path: 'invoices-list',
    loadChildren: () => import('./invoices-list/invoices-list.module').then( m => m.InvoicesListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-list',
    loadChildren: () => import('./user-list/user-list.module').then( m => m.UserListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices-create-new',
    loadChildren: () => import('./invoices-create-new/invoices-create-new.module').then( m => m.InvoicesCreateNewPageModule),
    canActivate: [AuthGuard]
  },  {
    path: 'user-edit',
    loadChildren: () => import('./user-edit/user-edit.module').then( m => m.UserEditPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
