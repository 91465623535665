import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthModule, getDeepFromObject, NbAuthJWTToken, NbPasswordAuthStrategyOptions, NbTokenLocalStorage, NbTokenStorage } from '@nebular/auth';
import { NbSecurityModule } from '@nebular/security';
import { NbPasswordIdentityServer4AuthStrategy } from './agt/identity/services/identityserver4-token-strategy';
import { ApiModule as EwalletApiModule, BASE_PATH as BASE_PATH_EWALLET } from '../../apis/ewallet';
import { ApiModule as IdentityApiModule, BASE_PATH as BASE_PATH_Identity } from '../../apis/identity';
import { HttpJwtInterceptorService } from './services/http-jwt-Interceptor.service';
import { from } from 'rxjs';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    // IdentityApiModule,
    EwalletApiModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    HttpClientModule,
    NbEvaIconsModule,
    NbSecurityModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordIdentityServer4AuthStrategy.setup({
          name: 'email',
          // baseEndpoint: 'https://dev-ewallet.playbrandsgroup.com/apis/identity',
          baseEndpoint: '/apis/identity',
          token: {
            class: NbAuthJWTToken,
            key: 'access_token',
            getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) => {
              const r = getDeepFromObject(
                res,
                options.token.key,
              );
              return r;
            },
          },
          login: {
            // ...
            endpoint: '/connect/token',
            redirect: {
              success: 'home', // welcome page path antes '/agt/employee/change-password'
              failure: null, // stay on the same page
            },
          },

          logout: {
            alwaysFail: false,
            endpoint: '/connect/endsession',
            method: 'get',
            redirect: {
              success: '/agt/identity/token-login',
              failure: null,
            },
          },

        })
      ],
      forms: {},
    }),
  ],
  providers: [
    { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
    { provide: BASE_PATH_EWALLET, useValue: '/apis/ewallet' },
    { provide: BASE_PATH_Identity, useValue: '/apis/identity' },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpJwtInterceptorService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
