import { Injectable } from '@angular/core';
import { InvoicesService } from 'apis/ewallet';
import { UtilService } from './util.service';
import { ValuesService } from './values.service';
import { Invoice } from '../../../apis/ewallet/model/invoice';
import { CreateTransferInput } from '../../../apis/ewallet/model/createTransferInput';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MyInvoicesService {

  invoices: Array<Invoice>;
  channel: string;
  skip: number;
  take: number;

  constructor(private valuesService: ValuesService,
              private router: Router,
              private utilService: UtilService,
              private invoicesService: InvoicesService) { }

  getInvoices() {

    this.utilService.presentLoading('esperando...', () => {
      // tslint:disable-next-line: max-line-length
      this.invoicesService.accountAccountIdInvoicesGet(this.valuesService.USER_ID + '|' + this.channel, 'pending', this.skip, this.take).pipe().subscribe( data => {
        console.log('accountAccountIdInvoicesGet data - ' + data);
        this.invoices = data as Array<Invoice>;

        console.log(this.invoices.length);
        this.utilService.loading.dismiss();
      }, error => {
        console.log('accountAccountIdInvoicesGet error - ' + error);
        this.utilService.loading.dismiss();
      });
    });

  }

  confirm(index: number, text: string = null) {
    this.utilService.presentLoading('esperando...', () => {
      // tslint:disable-next-line: max-line-length
      const id = this.invoices[index].id;
      this.invoicesService.invoiceInvoiceIdConfirmPost(id).pipe().subscribe(data => {
        console.log(data);
        this.getInvoices();
        this.utilService.loading.dismiss();
        this.router.navigate(['/feedback', 'ACREDITACION REALIZADA',
        '',
       'Lista de solicitudes',
       'invoices-list']);
      }, error => {
        console.log(error);
        this.utilService.loading.dismiss();
      });
    });
  }

  cancel(index: number, text: string = null) {
    this.utilService.presentLoading('esperando...', () => {
      const id = this.invoices[index].id;
      this.invoicesService.invoiceInvoiceIdCancelPost(id, text).pipe().subscribe(data => {
        console.log(data);
        this.getInvoices();
        this.utilService.loading.dismiss();
        this.router.navigate(['/feedback', 'ACREDITACION CANCELADA',
        '',
       'Lista de solicitudes',
       'invoices-list']);
      }, error => {
        console.log(error);
        this.utilService.loading.dismiss();
      });
    });
  }

  createInvoice(account: string, userIdResq: string, createTransferInput: CreateTransferInput) {

    // account/{fromAccountId}/invoice/{requestAccountId}
    this.utilService.presentLoading('esperando...', () => {

      // tslint:disable-next-line: max-line-length
      this.invoicesService.accountFromAccountIdInvoiceRequestAccountIdPost(account, userIdResq, createTransferInput).pipe().subscribe(data => {
        console.log(data);
        this.utilService.loading.dismiss();
        this.router.navigate(['/feedback', 'SOLICITUD ENVIADA',
        '',
       'Nueva solicitud',
       'invoices-create-new']);
      }, error => {
        console.log(error);
        this.utilService.loading.dismiss();
      });
    });

    // tslint:disable-next-line: max-line-length
    /*this.invoicesService.accountFromAccountIdInvoiceRequestAccountIdPost(this.valuesService.USER_ID + '|' + this.channel, userIdResq + '|' + this.channel, {
      "externalReference": null,
      "value": 20,
      "description": "a ver si funca"
    }).pipe().subscribe(data => {
      console.log(data);
      this.utilService.loading.dismiss();
    }, error => {
      console.log(error);
      this.utilService.loading.dismiss();
    });*/
  }

}


