import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  loading: any;
  flagGeneric: number;

  constructor(private loadingController: LoadingController) {
    this.flagGeneric = 0;
   }

  getFecha(date: string) {
    const dt = new Date(date);
    const y = dt.getFullYear().toString();
    let m: string;
    let d = dt.getDate().toString();

    if ((dt.getMonth() + 1).toString().length === 1) {
      m = '0' + (dt.getMonth() + 1).toString();
    } else {
      m = (dt.getMonth() + 1).toString();
    }
    if (d.length === 1) {
      d = '0' + d;
    }
    return d + '/' + m + '/' + y;
  }

  getHora(date: string) {
    const dt = new Date(date);
    let m: string;

    if (dt.getMinutes().toString().length === 1) {
      m = '0' + dt.getMinutes().toString();
    } else {
      m = dt.getMinutes().toString();
    }
    return dt.getHours().toString() + ':' + m;
  }

  utcToLocal(dateTochange: string) {
    const date = new Date(dateTochange);
    const myDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    return myDate;
  }

  // tslint:disable-next-line: ban-types
  async presentLoading( message: string, callback: Function ) {
    this.loading = await this.loadingController.create({
      message
    });
    callback();
    return this.loading.present();
  }

  doesFileExist(urlfile: string) {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlfile, false);

    try {
      xhr.send();
      if (xhr.status > 400) {
          return false;
      } else {
          return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

}
