import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ValuesService } from './values.service';
import { NbAuthService } from '@nebular/auth';


@Injectable({
    providedIn: 'root'
})
export class HttpJwtInterceptorService implements HttpInterceptor {

    constructor(private valuesService: ValuesService, private authService: NbAuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (this.valuesService.TOKEN && this.valuesService.ISLOGGEDIN) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.valuesService.TOKEN
          }
        });
      }
      return next.handle(req);
    }

    // Nebular interceptor
    // https://github.com/akveo/nebular/blob/master/src/framework/auth/services/interceptors/jwt-interceptor.ts
}