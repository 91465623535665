/**
 * Identity
 * Using our current Playbonds Database
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateUserDto { 
    username: string;
    fullname?: string | null;
    email?: string | null;
    phone?: string | null;
    city?: string | null;
    birthday?: string;
    documentId?: string | null;
    countryCode?: string | null;
}

