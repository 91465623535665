/**
 * Identity
 * Using our current Playbonds Database
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserListDto { 
    id?: number;
    username?: string | null;
    fullname?: string | null;
    registerDate?: string;
    status?: string | null;
}

